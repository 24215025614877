import { useEffect, useState } from "react"

import { useRouter } from "next/router"

import api from "api"
import { getAuthInfo } from "utils/auth"

type Props = {
  initialLoading?: boolean
  onLogin?: () => void
}

const useAutoLogin = ({
  initialLoading = false,
  onLogin = () => {},
}: Props) => {
  const isLoggedIn = Boolean(getAuthInfo())
  const router = useRouter()

  const { authCode: rawAuthCode, ...restQueries } = router.query
  const authCode = Array.isArray(rawAuthCode)
    ? rawAuthCode[0]
    : rawAuthCode ?? ""

  const [loading, setLoading] = useState(
    // eslint-disable-next-line no-nested-ternary
    authCode ? initialLoading ?? !isLoggedIn : false
  )

  useEffect(() => {
    const fetchData = async () => {
      if (authCode) {
        setLoading(true)
        const resUserDetails = await api.auth.generatePlatformToken({
          data: {
            platform: "Student",
            code: authCode,
          },
        })
        if (resUserDetails.isSuccessful) {
          localStorage.setItem("auth", JSON.stringify(resUserDetails.data))
          onLogin()
        } else {
          console.error("> Failed to auto login")
        }
        router.replace({
          query: restQueries,
        })
        setLoading(false)
      }
    }

    if (!router.isReady) return

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, router.isReady])

  return { loading }
}

export default useAutoLogin
