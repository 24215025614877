/* eslint-disable import/named */
/* eslint-disable import/no-cycle */
import { captureException } from "@sentry/react"
import {
  AxiosError,
  AxiosRequestTransformer,
  AxiosResponse,
  AxiosResponseTransformer,
} from "axios"
import camelCase from "lodash/camelCase"
import snakeCase from "lodash/snakeCase"

import { ErrorResponse, PaginatedResponse, SuccessResponse } from "api/types"
import { ServicePrefix } from "utils/constants"

const formatSuccessResponse = <T>(res: AxiosResponse): SuccessResponse<T> => ({
  isSuccessful: true,
  data: res.data.data,
  __data: res,
})

const formatPaginatedResponse = <T>(
  res: AxiosResponse
): PaginatedResponse<T> => ({
  isSuccessful: true,
  data: res.data,
  __data: res,
})

const isAxiosError = (error: unknown): error is AxiosError => {
  if (typeof error === "object" && error !== null) {
    if ("isAxiosError" in error) {
      // If the above check passes then we can be sure that the object is AxiosError
      return (error as AxiosError).isAxiosError
    }
  }
  return false
}

/**
 *
 * @param error This argument is `unknown` because it could be any JavaScript error.
 * We cannot be certain that it will be an `AxiosError` every time.
 *
 * @throws APIError
 */
export const formatErrorResponseNew = (error: unknown) => {
  if (!isAxiosError(error)) {
    throw error
  }

  throw new APIError(error)
}

export const formatErrorResponse = (error: unknown) => {
  if (!isAxiosError(error)) {
    throw error
  }

  return new APIError(error)
}

export class APIError extends Error {
  /**
   * True if the server responds with status code 500
   */
  is500: boolean

  /**
   * True if the server responds with status code 404 and content-length = 0
   */
  isEmpty404: boolean

  /**
   * True if either of the following occurs:
   * - There is a network failure on the client
   * - The server is unresponsive
   * - The request timed out
   */
  isNetworkFailure: boolean

  /**
   * Response status code
   */
  statusCode?: number

  /**
   * Contains the original error returned by axios.
   * Is useful when the consumer would like to access
   * other properties of the response which is very rare
   */
  __errors: AxiosError

  __error: AxiosError

  isSuccessful: false

  /**
   * Object containing a generic error message and 'key specific' error
   * There will always be one or the other. Not both together
   */
  errors: {
    /**
     * A generic error message describing the error (new structure)
     */
    message?: string
    /**
     * A generic error message describing the error (old structure)
     */
    detail?: string
    /**
     * These string keys contain the name of the key where the error has occurred
     */
    fieldErrors?: {
      [key: string]: string[] | string
    }
  }

  constructor(error: AxiosError) {
    super(`API Failed with status code: ${error.response?.status}`)

    this.__errors = error
    this.__error = error
    this.isSuccessful = false

    this.is500 = error.response?.status === 500
    this.isNetworkFailure = error.response === undefined
    this.isEmpty404 =
      error.response?.status === 404 &&
      error.response?.headers["content-length"] === "0"
    this.statusCode = error.response?.status

    let detail

    let message
    let fieldErrors

    if (!this.is500 && !this.isNetworkFailure && !this.isEmpty404) {
      detail = error.response?.data.errors?.detail ?? null
      if (!detail && error.response) {
        message = error.response.data.errors?.message ?? null
        if (message) delete error.response.data.errors.message
        fieldErrors = error.response.data.errors ?? null
      }
    }

    this.errors = {
      fieldErrors,
      message,
      detail,
    }
  }
}

export function isUUIDString(str: string) {
  if (str.includes("-")) return true
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

/**
 * Converts object keys to a specific format specified by the transformer function recursively
 */
const transformKeys = (obj: any, transformer: (arg: string) => string): any => {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map(v => transformKeys(v, transformer))
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj as object).reduce(
      (result, key) => ({
        ...result,
        /**
         * There are cases when object keys are UUIDs
         * In our previous setup they were getting converted into
         * camelCase, which is definitely not desirable
         * So, we skip transformation if keys are uuids
         */
        [isUUIDString(key) ? key : transformer(key)]: transformKeys(
          obj[key],
          transformer
        ),
      }),
      {}
    )
  }
  return obj
}

/**
 * Override axios's default implementation of serializing query params
 * we allow the following:
 * ?status=0&status=1
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function paramsSerializer(params: object | URLSearchParams = {}): string {
  let entries
  if ("entries" in params) {
    entries = Array.from(params.entries())
  } else entries = Object.entries(params)

  const arr: string[] = []
  for (const [key, value] of entries) {
    if (value === null) continue

    if (Array.isArray(value)) {
      value.forEach(v => arr.push(`${key}=${v as string}`))
    } else {
      arr.push(`${key}=${value as string}`)
    }
  }
  return arr.join("&")
}

/**
 * __noTransform is used to bypass snakeCase conversion. Reason for doing this is:
 * newPassword1 gets converted to new_password_1
 * But, the backend expects new_password1
 * However, the proper snake case implementation is new_password_1
 *
 * This work-around will be removed when backend has fixed their APIs
 */
const transformRequest: AxiosRequestTransformer = data => {
  if (data?.__noTransform) {
    delete data.__noTransform
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return transformKeys(data, snakeCase)
}

const transformResponse: AxiosResponseTransformer = data =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  transformKeys(data, camelCase)

export const getServiceURL = (base: ServicePrefix) => (url: string) =>
  `${base}${url}`

type APIErrorOptions = {
  feature: "talk-to-a-mentor"
}
/**
 * This function DOES NOT log for `statusCode = 401`
 */
const logAPIError = (
  errors: ErrorResponse | AxiosError,
  options?: APIErrorOptions
) => {
  try {
    let statusCode
    let originalError
    if (isAxiosError(errors)) {
      statusCode = errors.response?.status
      originalError = errors
    } else {
      statusCode = errors.statusCode
      originalError = errors.__error
    }

    if (statusCode === 401) return

    const { config } = originalError

    // @ts-ignore we can delete this key now
    delete errors.__error

    const fullURL = originalError.request.responseURL
    const shortURL = config.url

    const data = {
      response: errors,
      rawResponse: originalError.request.response,
      request: {
        url: fullURL,
        method: config.method,
        headers: config.headers,
        data: config.data,
      },
    }
    // Debugging purpose
    // console.log(JSON.stringify(data, null, 2))
    // console.log(`Logging API ERROR: API Error (${statusCode}) at: ${shortURL}`)
    captureException(
      new Error(`API Error (${statusCode}) at: ${shortURL}`),
      scope => {
        if (options?.feature) scope.setTag("feature", options.feature)
        scope.setExtras({
          request: JSON.stringify(data.request, null, 2),
          response: JSON.stringify(data.response, null, 2),
          rawResponse: data.rawResponse,
        })
        return scope
      }
    )
  } catch (e) {
    captureException(new Error(`Logger Error`), scope => {
      if (options?.feature) scope.setTag("feature", options.feature)
      scope.setExtras({
        error: JSON.stringify(e),
      })
      return scope
    })
  }
}

export {
  formatPaginatedResponse,
  formatSuccessResponse,
  logAPIError,
  paramsSerializer,
  transformKeys,
  transformRequest,
  transformResponse,
}
