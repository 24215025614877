import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  auth: {
    refreshToken: () => getSolisURL(`/v1/auth/refresh-token/`),
    generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  },
  closedBeta: {
    overview: () => getSolisURL("/v1/closed-beta/jobs/overview/"),
    signup: () => getSolisURL("/v1/closed-beta/users/"),
    resendVerificationEmail: () =>
      getSolisURL("/v1/closed-beta/users/resend-email/"),
  },
  profile: {
    markAsComplete: () => "/jobs/users/marketing-form/complete/",
  },
  stepsOverview: {
    list: () => "/jobs/users/marketing-form/overview/",
  },
})
