/* eslint-disable import/no-self-import */
/* eslint-disable import/named */
/* eslint-disable import/no-cycle */
import Axios, { AxiosError, AxiosRequestConfig } from "axios"

import { getAuthInfo } from "utils/auth"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/constants"

import { refreshToken } from "./refreshToken"
import { paramsSerializer, transformRequest, transformResponse } from "./utils"

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
  withCredentials: true,
  paramsSerializer,
  transformRequest: [
    transformRequest,
    ...(Array.isArray(Axios.defaults.transformRequest)
      ? Axios.defaults.transformRequest
      : []),
  ],
  transformResponse: [
    ...(Array.isArray(Axios.defaults.transformResponse)
      ? Axios.defaults.transformResponse
      : []),
    transformResponse,
  ],
  headers: {
    "Content-Type": "application/json",
    Language: "en",
  },
})

const authorizationIsEmpty = (request: AxiosRequestConfig) =>
  /**
   * If we send null in Authorization header from where the API is being called, axios transforms it into an empty object `{}`
   * That is why we are checking for object length and not null
   */
  request.headers?.Authorization != null &&
  Object.keys(request.headers.Authorization).length === 0

axios.interceptors.request.use(async request => {
  let authInfo = getAuthInfo()

  /**
   * Empty "Authorization" header means that we don't want to send this Authorization in the request even if accessToken is present in the localStorage
   * Example: in refresh token API call, we explicitly send Authorization: null from the API call.
   */
  if (authorizationIsEmpty(request)) {
    delete request.headers?.Authorization
  } else if (authInfo && !request.headers?.Authorization) {
    const isAuthTokenExpired = authInfo.expiresAt
      ? authInfo.expiresAt <= new Date().toISOString()
      : false

    // Refreshing Access token incase of expiry
    if (isAuthTokenExpired) {
      await refreshToken()
      authInfo = getAuthInfo()
    }

    const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${authInfo?.accessToken}`
    if (request.headers) request.headers.Authorization = tokenString
  }

  if (request.headers) request.headers.Language = "en"

  return request
})

const interceptor = async (error: AxiosError) => {
  if (error.response && error.response.status === 401) {
    const refreshed = await refreshToken()
    if (refreshed) {
      // Retrying the failed api call with new access token
      if (error.response.config.headers) {
        error.response.config.headers.Authorization = `${AUTHORIZATION_HEADER_PREFIX} ${
          getAuthInfo()?.accessToken
        }`
      }
      return axios(error.response.config)
    }
  }

  return Promise.reject(error)
}

axios.interceptors.response.use(undefined, interceptor)

export default axios
