import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSiriusURL = getServiceURL(ServicePrefix.sirius)
export const urls = validateUrls({
  attempt: {
    start: () => getSiriusURL("/v1/applications/"),
    createResponse: questionId =>
      getSiriusURL(`/v1/applications/questions/${questionId}/responses/`),
    validateStep: () => getSiriusURL("/v1/applications/validate/"),
    submit: () => getSiriusURL("/v1/applications/"),
  },
  applicant: {
    update: () => getSiriusURL("/v1/applications/applicants/"),
    detail: () => getSiriusURL("/v1/applications/detail/"),
  },
  address: {
    create: () => getSiriusURL("/v1/applications/addresses/"),
    update: addressId =>
      getSiriusURL(`/v1/applications/addresses/${addressId}/`),
  },
  languageProficiencies: {
    create: () => getSiriusURL("/v1/profiles/languages/"),
    base: languageProficiencyId =>
      getSiriusURL(`/v1/profiles/languages/${languageProficiencyId}/`),
  },
  qualification: {
    create: () => getSiriusURL("/v1/profiles/qualifications/"),
    base: qualificationId =>
      getSiriusURL(`/v1/profiles/qualifications/${qualificationId}/`),
  },
  certification: {
    create: () => getSiriusURL("/v1/profiles/certifications/"),
    base: certificationId =>
      getSiriusURL(`/v1/profiles/certifications/${certificationId}/`),
  },
  references: {
    create: () => getSiriusURL("/v1/applications/references/"),
    base: referenceId =>
      getSiriusURL(`/v1/applications/references/${referenceId}/`),
  },
  workExperiences: {
    create: () => getSiriusURL("/v1/profiles/work-experiences/"),
    base: workExperienceId =>
      getSiriusURL(`/v1/profiles/work-experiences/${workExperienceId}/`),
  },
  certificates: {
    base: () => getSiriusURL("/v1/profiles/certificates/"),
  },
  syncStatus: {
    base: () => getSiriusURL("/v1/profiles/sync-status/"),
  },
  profiles: {
    base: () => getSiriusURL("/v1/profiles/"),
  },
  review: {
    list: () => getSiriusURL("/v1/reviews/changes/"),
    update: () => getSiriusURL("/v1/reviews/"),
  },
})
